<i18n>
{
  "de": {
    "scenario": "Szenario:"
  }
}
</i18n>

<template>
  <div class="c-scenario-picker">
    <span class="scenario-label">{{ label || $t('scenario') }}</span>

    <Menu left :buttonText="model.description">
      <template #icon>
        <ScenarioIcon :color="model.color" />
      </template>
      <template #options>
        <MenuItem
          v-for="scenario in scenarios"
          :id="`scenario-${scenario.id}`"
          :key="scenario.id"
          :label="scenario.description"
          :type="'label'"
          :value="scenario"
          :class="{ 'is-selected': model.id === scenario.id }"
          @click="onSelectScenario"
        >
          <template #icon>
            <ScenarioIcon :color="scenario.color" />
          </template>
        </MenuItem>
      </template>
    </Menu>
  </div>
</template>

<script>
import ScenarioIcon from '@/components/shared/ScenarioIcon.vue'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuItem,
    ScenarioIcon,
  },

  props: {
    portfolio: {
      type: Object,
    },
    value: {
      type: Object,
    },
    label: {
      type: String,
    },
    hideCapex: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  computed: {
    scenarios() {
      return this.portfolio.scenarios.filter((scenario) => !this.hideCapex || scenario.description !== 'CAPEX')
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    if (this.value) {
      this.model = this.value
    } else {
      this.model = this.portfolio.scenarios[0]
      this.$emit('input', this.model)
    }
  },

  methods: {
    onSelectScenario(scenario) {
      this.model = scenario
      this.$emit('input', this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-scenario-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-l);

  & .scenario-label {
    margin-right: var(--spacing-s);
  }

  & .is-selected {
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.c-scenario-picker {
  & .button {
    font-weight: 500 !important;

    & .button-text {
      max-width: 200px;
    }
  }
}
</style>
