<i18n>
{
  "de": {
    "title": "Zusammenfassung: {sheet}",
    "statsTitle": "Statistik",
    "close": "Schliessen",
    "sheet": "Sheet",
    "row": "Zeile",
    "column": "Spalte",
    "error": "Fehler",
    "warning": "Warnung",
    "stats": {
      "created": "Erstellt",
      "updated": "Aktualisiert",
      "deleted": "Gelöscht",
      "ignored": "Ignoriert",
      "noChanges": "Keine Änderungen"
    },
    "errors": {
      "INVALID": "Ungültiger Wert",
      "MISSING": "Fehlender Wert",
      "UNKNOWN_BUILDING_IDENTIFIER": "Unbekannter Gebäude-Identifier",
      "UNKNOWN_METER_TYPE": "Unbekannter Verbrauchsdatentyp",
      "UNKNOWN_ENERGY_CARRIER": "Unbekannter Energieträger",
      "INVALID_ENERGY_CARRIER_FOR_ELECTRICITY_GENERAL_METER": "Ungültiger Energieträger für Allgemeinstrom Verbrauchsdaten",
      "INVALID_ENERGY_CARRIER_FOR_ELECTRICITY_TENANT_METER": "Ungültiger Energieträger für Mieterstrom Verbrauchsdaten",
      "INVALID_ENERGY_CARRIER_FOR_ELECTRICITY_PV_METER": "Ungültiger Energieträger für Photovoltaik Verbrauchsdaten",
      "INVALID_ENERGY_CARRIER_FOR_END_ENERGY_HEATER_METER": "Ungültiger Energieträger für Heizungsverbrauchsdaten",
      "ERROR_PROCESSING_METER": "Fehler beim Verarbeiten der Verbrauchsdaten",
      "ERROR_PROCESSING_BUILDING": "Fehler beim Verarbeiten des Gebäudes",
      "IGNORED_GIVEN_BUILDING_STATUS": "Ignoriert wegen Gebäude-Status",
      "ERROR_PROCESSING_MEASURE": "Fehler beim Verarbeiten der Massnahme"
    },
    "warnings": {
      "EXCEL_ROUNDING": "Excel automatische Rundung"
    }
  }
}
</i18n>

<template>
  <Modal class="c-import-result-modal" @close="$emit('close', $event)">
    <template #header>
      <div class="title">{{ $t('title', { sheet: data[0].sheet }) }}</div>
    </template>

    <div v-for="item in data" :key="item.sheet" class="sheet-result">
      <div>
        {{ `${$t('sheet')}: ` }}<strong>{{ item.sheet }}</strong>
      </div>

      <!-- Stats -->
      <div
        v-if="item?.stats?.created || item?.stats?.updated || item?.stats?.deleted || item?.stats?.ignored"
        class="stats"
      >
        <div class="title">{{ $t('statsTitle') }}</div>
        <div v-if="item?.stats?.created">{{ `${$t('stats.created')}: ${item?.stats?.created}` }}</div>
        <div v-if="item?.stats?.updated">{{ `${$t('stats.updated')}: ${item?.stats?.updated}` }}</div>
        <div v-if="item?.stats?.deleted">{{ `${$t('stats.deleted')}: ${item?.stats?.deleted}` }}</div>
        <div v-if="item?.stats?.ignored">{{ `${$t('stats.ignored')}: ${item?.stats?.ignored}` }}</div>
      </div>
      <div v-else class="stats">
        <div>{{ $t('stats.noChanges') }}</div>
      </div>

      <!-- Errors -->
      <div v-if="item?.errors?.length" class="errors">
        <div class="title error-row">
          <div>{{ $t('row') }}</div>
          <div>{{ $t('column') }}</div>
          <div>{{ $t('error') }}</div>
        </div>
        <div v-for="error in item?.errors" :key="error.index" class="error-row">
          <div>{{ error.row }}</div>
          <div>{{ error.column }}</div>
          <div>{{ $t(`errors.${error.code}`) }}</div>
        </div>
      </div>

      <!-- Warnings -->
      <div v-if="item?.warnings?.length" class="warnings">
        <div class="title warning-row">
          <div>{{ $t('row') }}</div>
          <div>{{ $t('column') }}</div>
          <div>{{ $t('warning') }}</div>
        </div>
        <div v-for="warning in item?.warnings" :key="warning.index" class="warning-row">
          <div>{{ warning.row }}</div>
          <div>{{ warning.column }}</div>
          <div>
            <div>{{ $t(`warnings.${warning.data.code}`) }}</div>
            <div class="warning-row-change">{{ `${warning.data.before} -> ${warning.data.after}` }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <Button :text="$t('close')" @click="$emit('close')" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  name: 'ImportResultModal',

  components: {
    Modal,
    Button,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.c-import-result-modal {
  & .sheet-result {
    padding: var(--spacing-s) 0;
  }

  & .stats,
  & .errors,
  & .warnings {
    padding-top: var(--spacing-xs);
  }

  & .title {
    font-weight: bold;
  }

  & .error-row,
  & .warning-row {
    display: table-row;

    & .warning-row-change {
      font-size: 11px;
    }

    & > div {
      display: table-cell;
      padding-left: var(--spacing-xxs);
      padding-right: var(--spacing-xxs);
    }
  }

  & .info {
    margin-top: var(--spacing-s);
  }

  & .buttons {
    padding-top: var(--spacing-m);
  }
}
</style>
