<i18n>
{
  "de": {
    "pageTitle": "Import & Export",
    "buildings": "Liegenschaften",
    "buildingsSubtitle": "(Stammdaten & CAPEX)",
    "buildingsDescription": "Sie können neue Liegenschaften erstellen und die Details der bestehenden Liegenschaften editieren. Ausserdem können Sie die geplante CAPEX-Massnahmen erstellen und die Details von bestehende CAPEX-Massnahmen anpassen.",
    "meters": "Controlling",
    "metersSubtitle": "(Verbrauchsdaten)",
    "metersDescription": "Sie können neue Verbrauchsdaten erstellen und die Details der bestehenden Verbrauchsdaten editieren.",
    "measures": "Massnahmen-Planung",
    "measuresSubtitle": "(Szenarien)",
    "measuresDescription": "Sie können die Massnahmen eines Szenarios bearbeiten. Sie können neue Massnahmen planen und vorherige als umgesetzt markieren. Sie müssen einem Datei pro Szenario verwenden.",
    "emissionsFactors": "Emissionsfaktoren",
    "emissionsFactorsSubtitle": "(Energieträger)",
    "emissionsFactorsDescription": "Sie können neue Energieträger erstellen und die Details der bestehenden Energieträger editieren. Ausserdem können Sie die Emissionsfaktoren der Energieträger definieren.",
    "importLiegenschaften": "Liegenschaften importieren",
    "exportLiegenschaften": "Liegenschaften herunterladen",
    "importVerbrauchsdaten": "Verbrauchsdaten importieren",
    "exportVerbrauchsdaten": "Verbrauchsdaten herunterladen",
    "importMassnahmen": "Massnahmen importieren",
    "exportMassnahmen": "Massnahmen herunterladen",
    "importEmissionsfaktoren": "Emissionsfaktoren importieren",
    "exportEmissionsfaktoren": "Emissionsfaktoren herunterladen",
    "metersFileName": "{portfolioName} - Verbrauchsdaten.xlsx",
    "measuresFileName": "{portfolioName} - {scenarioDescription} - Massnahmen.xlsx",
    "buildingsFileName": "{portfolioName} - Stammdaten.xlsx",
    "importIndicatorsTitle": "Emissionsfaktoren importieren (remove)"
  }
}
</i18n>
<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="p-import-export">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <template #default>
      <!-- Buildings -->
      <ImportExportSection
        :title="$t('buildings')"
        :subtitle="$t('buildingsSubtitle')"
        :description="$t('buildingsDescription')"
        file-type-extension="xlsx"
        :import-button-text="$t('importLiegenschaften')"
        :export-button-text="$t('exportLiegenschaften')"
        :portfolio="portfolio"
        import-job-key="BUILDINGS"
        :import-callback="importBuildings"
        :download-callback="exportBuildings"
      />

      <!-- Meters -->
      <ImportExportSection
        :title="$t('meters')"
        :subtitle="$t('metersSubtitle')"
        :description="$t('metersDescription')"
        file-type-extension="xlsx"
        :import-button-text="$t('importVerbrauchsdaten')"
        :export-button-text="$t('exportVerbrauchsdaten')"
        :portfolio="portfolio"
        import-job-key="METERS"
        :import-callback="importMeters"
        :download-callback="exportMeters"
      />

      <!-- Measures -->
      <ImportExportSection
        :title="$t('measures')"
        :subtitle="$t('measuresSubtitle')"
        :description="$t('measuresDescription')"
        file-type-extension="xlsx"
        :import-button-text="$t('importMassnahmen')"
        :export-button-text="$t('exportMassnahmen')"
        :scenario-specific="true"
        :portfolio="portfolio"
        import-job-key="MEASURES"
        :import-callback="importMeasures"
        :download-callback="exportMeasures"
      />

      <!-- Emissions Factors -->
      <ImportExportSection
        :title="$t('emissionsFactors')"
        :subtitle="$t('emissionsFactorsSubtitle')"
        :description="$t('emissionsFactorsDescription')"
        file-type-extension="xlsx"
        :import-button-text="$t('importEmissionsfaktoren')"
        :export-button-text="$t('exportEmissionsfaktoren')"
        :portfolio="portfolio"
        import-job-key="ENERGY_CARRIERS"
        :import-callback="importEnergyCarriers"
        :download-callback="exportEnergyCarriers"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'

import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioBuildingsForListMixin from '@/pages/vuex-mixins/PortfolioBuildingsForListMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import ImportExportSection from '@/components/portfolio/import-export/ImportExportSection.vue'

import de from '@/locales/de.json'

export default {
  name: 'import',

  mixins: [
    //
    PortfolioMixin,
    PortfolioBuildingsForListMixin,
  ],

  components: {
    MainLayout,
    PageTitle,
    ImportExportSection,
  },

  data() {
    return {
      // Validation before import
      metersValidationErrors: null,
      // Errors during import
      metersImportErrors: null,
    }
  },

  methods: {
    //
    // Buildings
    //

    async importBuildings(file) {
      const translations = {
        heritageProtectionTypes: de._heritageProtectionTypes,
        energyCarriers: de._energyCarriers,
        heatingTypes: de._heatingTypes,
        distributionTypes: de._distributionTypes,
        roofTypes: de._roofTypes,
        usageTypes: de._usageTypes,
        boolean: de._boolean,
        phaseTypes: de._phaseTypes,
      }
      const formData = new FormData()
      const apiUrl = `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/buildings/xlsx`
      formData.append('file', file)
      formData.append('translations', JSON.stringify(translations))

      const result = await this.uploadFile(apiUrl, formData)
      return result
    },

    async exportBuildings() {
      const translations = {
        heritageProtectionTypes: de._heritageProtectionTypes,
        energyCarriers: de._energyCarriers,
        heatingTypes: de._heatingTypes,
        distributionTypes: de._distributionTypes,
        roofTypes: de._roofTypes,
        usageTypes: de._usageTypes,
        boolean: de._boolean,
        phaseTypes: de._phaseTypes,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/export/portfolio/${this.portfolio.id}/buildings/xlsx`
      const fileName = this.$t('buildingsFileName', { portfolioName: this.portfolio.name })

      await this.downloadFile(apiUrl, fileName, translations)
    },

    //
    // Meters
    //

    async importMeters(file, forceImport = false) {
      const translations = {
        energyCarriers: de._energyCarriers,
        meterTypes: de._meterTypes,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/meters/xlsx`
      const formData = new FormData()

      formData.append('file', file)
      formData.append('translations', JSON.stringify(translations))

      if (forceImport) {
        formData.append('ignoreErrors', 'True')
      }

      const result = await this.uploadFile(apiUrl, formData)
      return result
    },

    async exportMeters() {
      const translations = {
        energyCarriers: de._energyCarriers,
        meterTypes: de._meterTypes,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/export/portfolio/${this.portfolio.id}/meters/xlsx`
      const fileName = this.$t('metersFileName', { portfolioName: this.portfolio.name })
      await this.downloadFile(apiUrl, fileName, translations)
    },

    //
    // Measures
    //

    async importMeasures(file, forceImport = false, scenarioId) {
      const translations = {
        heatingTypes: de._heatingTypes,
        phaseTypes: de._phaseTypes,
        usageTypes: de._usageTypes,
        boolean: de._boolean,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/scenario-measures/${scenarioId}/xlsx`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('translations', JSON.stringify(translations))

      if (forceImport) {
        formData.append('ignoreErrors', 'True')
      }

      const result = await this.uploadFile(apiUrl, formData)
      return result
    },

    async exportMeasures(scenario) {
      const translations = {
        heatingTypes: de._heatingTypes,
        phaseTypes: de._phaseTypes,
        usageTypes: de._usageTypes,
        boolean: de._boolean,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/export/portfolio/${this.portfolio.id}/scenario-measures/${scenario.id}/xlsx`
      const fileName = this.$t('measuresFileName', {
        portfolioName: this.portfolio.name,
        scenarioDescription: scenario.description.trim(),
      })
      await this.downloadFile(apiUrl, fileName, translations)
    },

    //
    // Emissions Factors
    //

    async importEnergyCarriers(file) {
      const translations = {
        energyCarriers: de._energyCarriers,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/energy-carriers/xlsx`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('translations', JSON.stringify(translations))

      const result = await this.uploadFile(apiUrl, formData)
      return result
    },

    async exportEnergyCarriers() {
      const translations = {
        energyCarriers: de._energyCarriers,
      }
      const apiUrl = `${process.env.VUE_APP_API_URL}/export/portfolio/${this.portfolio.id}/energy-carriers/xlsx`
      const fileName = this.$t('energyCarriersFileName', { portfolioName: this.portfolio.name })
      await this.downloadFile(apiUrl, fileName, translations)
    },

    //
    // Utils
    //

    async uploadFile(apiUrl, formData) {
      const token = this.$store.getters['account/token']
      let headers = {}
      if (token) {
        headers['Authentication-Token'] = token
      }

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
          headers,
        })

        return await response.json()
      } catch (error) {
        return {
          code: 'UPLOAD_ERROR',
          error: true,
          message: error.message,
        }
      }
    },

    async downloadFile(apiUrl, fileName, translations) {
      const token = this.$store.getters['account/token']
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      if (token) {
        headers['Authentication-Token'] = token
      }

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify(translations),
          headers,
        })

        if (!response.ok) {
          return {
            code: 'DOWNLOAD_ERROR',
            error: true,
            message: response.statusText,
          }
        }

        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        const safeFileName = fileName.replace(/[<>:"/\\|?*]/g, '_')

        link.href = downloadUrl
        link.download = safeFileName
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        return {
          code: 'DOWNLOAD_ERROR',
          error: true,
          message: error.message,
        }
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.p-import-export {
  & .component-box {
    max-width: 500px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
    padding: var(--spacing-m);
    background-color: var(--background-color);
    border: var(--box-border);
    border-radius: var(--box-radius);

    & .description {
      margin-bottom: var(--spacing-s);
    }

    & .action-buttons {
      display: flex;
      align-items: center;
      gap: var(--spacing-s);

      & .hidden {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.p-import-export {
  & .button {
    margin: 0;
  }
}
</style>
