<i18n>
  {
    "de": {
      "processingImport": "Import wird durchgeführt...",
      "processingExport": "Export wird durchgeführt...",
      "showReportDetails": "Details",
      "dismissJob": "Info entfernen",
      "continueImport": "Import fortsetzen",
      "importInputLabel": ".xlsx-Datei importieren",
      "importInputDescription": "Hier klicken oder ablegen",
      "importStatus": {
        "CHECKING_JOB_STATUS": "Import-Status wird überprüft...",
        "VALIDATION": ".xlsx-Datei wird überprüft...",
        "PROCESSING": "Import läuft..."
      },
      "exportStatus": {
        "PROCESSING": "Export-Datei wird generiert..."
      },
      "feedbackMessage": {
        "FILE_NOT_FOUND": "Datei nicht gefunden",
        "NO_FILE_PROVIDED": "Keine Datei ausgewählt",
        "VALIDATION_ERRORS": "Überprüfung abgeschlossen mit Fehlern",
        "IMPORT_PROCESS_STARTED": "Import gestartet...",
        "IMPORT_COMPLETED": "Import fertig",
        "IMPORT_COMPLETED_WITH_ERRORS": "Import fertig mit Fehlern",
        "INVALID_FILE_TYPE": "Ungültige Datei-Typ",
        "DOWNLOAD_ERROR": "Fehler beim Herunterladen",
        "UPLOAD_ERROR": "Fehler beim Hochladen",
        "INVALID_TEMPLATE_FILE": "Ungültige Vorlagen-Datei",
        "IMPORT_FAILED": "Import fehlgeschlagen",
        "IMPORT_INFO_NOT_AVAILABLE": "Import-Info nicht verfügbar"
      }
    }
  }
</i18n>

<template>
  <div class="c-import-export-section">
    <div class="content">
      <div class="title-container">
        <div class="title">
          <strong>{{ title }}</strong>
          <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
        </div>

        <p class="description">
          {{ description }}
        </p>
      </div>

      <div class="import-export-container">
        <!-- Buttons -->
        <div class="export-button-container">
          <!-- Scenario picker -->
          <ScenarioPicker
            v-if="scenarioSpecific"
            v-model="selectedScenario"
            :portfolio="portfolio"
            hide-capex
            class="scenario-picker"
          />
          <!-- Export button -->
          <Button :text="exportButtonText" icon="download" :disabled="isExportProcessing" @click="downloadFile" />
        </div>

        <!-- Upload -->
        <button
          type="button"
          class="button drop-zone"
          :class="{ active: isDragging }"
          @click="triggerFileUpload"
          @dragenter.prevent="handleDragEnter"
          @dragleave.prevent="handleDragLeave"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <div class="drop-zone-content">
            <Icon icon="excel" class-name="drop-zone-icon" />
            <div class="drop-zone-label">{{ $t('importInputLabel') }}</div>
            <div class="drop-zone-description">{{ $t('importInputDescription') }}</div>
          </div>

          <!-- File input -->
          <input
            ref="fileInput"
            type="file"
            :accept="`.${fileTypeExtension}`"
            class="hidden"
            @change="handleFileUpload"
          />

          <!-- Import button -->
          <!-- <Button :disabled="isExportProcessing" @click="triggerFileUpload" /> -->
        </button>

        <!-- Feedback boxes -->
        <div>
          <!-- Feedback: Export processing -->
          <div v-if="isExportProcessing" class="feedback-box">
            <div class="spinner-container">
              <ClipLoader class="spinner" size="16px" color="#000" />
              <div>
                {{ $t('exportStatus.PROCESSING') }}
              </div>
            </div>
          </div>

          <!-- Feedback: Import processing -->
          <div v-if="isImportProcessing" class="feedback-box">
            <!-- Loading -->
            <div class="spinner-container">
              <ClipLoader class="spinner" size="16px" color="#000" />
              <div>
                {{ importStatusText }}
                <span v-if="importProgress > 0">{{ importProgress }}%</span>
              </div>
            </div>
            <div v-if="importProgress > 0" class="progress-bar-container">
              <div class="progress-bar" :style="{ width: `${importProgress}%` }"></div>
            </div>
          </div>

          <!-- Messages -->
          <div v-if="result?.code" class="feedback-box">
            <div class="message-container">
              <Icon v-if="result?.code === 'IMPORT_COMPLETED'" icon="check-small" class-name="message-icon" />
              <Icon
                v-if="
                  result?.code === 'IMPORT_COMPLETED_WITH_ERRORS' ||
                  result?.code === 'VALIDATION_ERRORS' ||
                  result?.code === 'INVALID_FILE_TYPE' ||
                  result?.code === 'INVALID_TEMPLATE_FILE' ||
                  result?.code === 'NO_FILE_PROVIDED' ||
                  result?.code === 'DOWNLOAD_ERROR' ||
                  result?.code === 'UPLOAD_ERROR' ||
                  result?.code === 'IMPORT_INFO_NOT_AVAILABLE'
                "
                icon="alert-triangle"
                class-name="message-icon"
              />
              <div class="message">
                {{ $t(`feedbackMessage.${result?.code}`) }}
              </div>
              <button v-if="isForceImportPossible" type="button" class="button message-button" @click="onForceImport">
                {{ $t('continueImport') }}
              </button>
              <button v-if="result?.data" type="button" class="button message-button" @click="onOpenResultModal">
                {{ $t('showReportDetails') }}
              </button>
            </div>

            <!-- Close button -->
            <button v-if="result?.code" type="button" class="close-button" @click="deleteImportJob">
              <img class="icon" src="/icons/x.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <ImportResultModal v-if="isResultModalOpen" :data="result?.data" @close="onCloseResultModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import Button from '@/components/cui/inputs/Button.vue'
import Icon from '@/components/cui/data-display/Icon.vue'

import ImportResultModal from '@/components/portfolio/import-export/ImportResultModal.vue'

export default {
  name: 'ImportExportSection',
  components: {
    Button,
    ClipLoader,
    Icon,
    ImportResultModal,
    ScenarioPicker,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      required: true,
    },
    fileTypeExtension: {
      type: String,
      default: 'xlsx',
    },
    exportButtonText: {
      type: String,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    scenarioSpecific: {
      type: Boolean,
      default: false,
    },
    importJobKey: {
      type: String,
      required: true,
    },
    importCallback: {
      type: Function,
      default: null,
    },
    downloadCallback: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      importStatus: null, // VALIDATION, PROCESSING
      importProgress: 0,
      exportStatus: null, // PROCESSING
      /**
       * Endpoint result
       * {
       *   code: string,
       *   error: boolean,
       *   message: string,
       *   data: any
       * }
       */
      result: null,
      isResultModalOpen: false,
      selectedScenario: null,
      isDragging: false,
      dragCounter: 0,
    }
  },

  computed: {
    ...mapGetters({
      hasActiveJob: 'importExport/hasActiveJob',
      getFile: 'importExport/file',
    }),

    isImportProcessing() {
      return ['VALIDATION', 'PROCESSING', 'CHECKING_JOB_STATUS'].includes(this.importStatus)
    },

    isExportProcessing() {
      return this.exportStatus === 'PROCESSING'
    },

    importStatusText() {
      return this.$t(`importStatus.${this.importStatus}`)
    },

    isForceImportPossible() {
      const storedFile = this.getFile(this.portfolio.id, this.importJobKey)
      return this.result?.code === 'VALIDATION_ERRORS' && this.result?.['can_continue'] && storedFile
    },
  },

  mounted() {
    this.importStatus = 'CHECKING_JOB_STATUS'
    this.watchImportProgress()
  },

  destroyed() {
    if (this.isImportProcessing) {
      this.$store.dispatch('importExport/stopJobProgress', {
        portfolioId: this.portfolio.id,
        jobKey: this.importJobKey,
      })
    }
  },

  methods: {
    //
    triggerFileUpload() {
      this.$store.dispatch('importExport/setFile', {
        portfolioId: this.portfolio.id,
        key: this.importJobKey,
        file: null,
      })
      this.result = null
      this.$refs.fileInput.click()
    },

    //
    async handleFileUpload(event, force = false) {
      const storedFile = this.$store.getters['importExport/file'](this.portfolio.id, this.importJobKey)
      let file = this.$refs.fileInput.files?.[0]

      // If file is selected and not stored, store it
      if (file && !storedFile) {
        this.$store.dispatch('importExport/setFile', { portfolioId: this.portfolio.id, key: this.importJobKey, file })
      }

      // If no file is selected but one is stored, use it
      if (!file && storedFile) {
        file = storedFile
      }

      if (file) {
        this.importStatus = 'VALIDATION'
        const result = await this.importCallback(file, force, this.selectedScenario?.id)

        // Reset file input so same file can be selected again
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = null
        }

        if (['IMPORT_PROCESS_STARTED', 'IMPORT_PROCESS_COMPLETED', 'VALIDATION_ERRORS'].includes(result?.code)) {
          // Start asking for import progress
          this.watchImportProgress()
        } else if (result?.error) {
          // Clear status in case of errors
          this.importStatus = null
          this.result = result
        }
      } else {
        this.result = {
          code: 'NO_FILE_PROVIDED',
          error: true,
        }
        this.importStatus = null
      }
    },

    //
    async onForceImport() {
      this.result = null
      await this.handleFileUpload(null, true)
    },

    //
    async fetchImportJob() {
      const token = this.$store.getters['account/token']
      let headers = {}
      if (token) {
        headers['Authentication-Token'] = token
      }
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/import-jobs/${this.importJobKey}`,
        {
          headers,
        }
      )
      const result = await response.json()

      return result
    },

    //
    async watchImportProgress() {
      this.result = null

      // Start the job progress monitoring
      this.$store.dispatch('importExport/startJobProgress', {
        portfolioId: this.portfolio.id,
        jobKey: this.importJobKey,
        callback: async () => {
          try {
            const job = await this.fetchImportJob()
            this.importProgress = job.progress
            this.importStatus = job.status

            if (job.status === 'COMPLETED') {
              this.$store.dispatch('importExport/stopJobProgress', {
                portfolioId: this.portfolio.id,
                jobKey: this.importJobKey,
              })
              this.importStatus = null
              this.importProgress = 0
              this.result = job.result
            } else if (job.code === 'JOB_NOT_FOUND') {
              this.$store.dispatch('importExport/stopJobProgress', {
                portfolioId: this.portfolio.id,
                jobKey: this.importJobKey,
              })
              this.importStatus = null
              this.result = null
              this.importProgress = 0
            } else if (job.code === 'VALIDATION_ERRORS') {
              this.$store.dispatch('importExport/stopJobProgress', {
                portfolioId: this.portfolio.id,
                jobKey: this.importJobKey,
              })
              this.importStatus = null
              this.result = job
            }
          } catch (error) {
            this.$store.dispatch('importExport/stopJobProgress', {
              portfolioId: this.portfolio.id,
              jobKey: this.importJobKey,
            })
            this.importStatus = null
            this.result = {
              code: 'IMPORT_INFO_NOT_AVAILABLE',
              error: true,
            }
            this.importProgress = 0
          }
        },
      })
    },

    //
    async deleteImportJob() {
      const token = this.$store.getters['account/token']
      let headers = {}
      if (token) {
        headers['Authentication-Token'] = token
      }
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/import/portfolio/${this.portfolio.id}/import-jobs/${this.importJobKey}`,
        {
          headers,
          method: 'DELETE',
        }
      )
      const result = await response.json()

      if (result.code === 'JOB_DELETED') {
        this.result = null
        this.importStatus = null
        this.importProgress = 0
      }
    },

    //
    async downloadFile() {
      this.result = null
      this.exportStatus = 'PROCESSING'
      await this.downloadCallback(this.selectedScenario)
      this.exportStatus = null
    },

    // Drag and drop handlers

    //
    handleDragEnter(e) {
      this.dragCounter++
      this.isDragging = true

      // Check if the dragged item is a file
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        const types = Array.from(e.dataTransfer.items).map((item) => item.kind)
        if (!types.includes('file')) {
          this.isDragging = false
        }
      }
    },

    //
    handleDragLeave() {
      this.dragCounter--
      if (this.dragCounter <= 0) {
        this.dragCounter = 0
        this.isDragging = false
      }
    },

    //
    handleDrop(e) {
      this.isDragging = false
      this.dragCounter = 0

      const files = e.dataTransfer.files
      if (files.length > 0) {
        // Validate file type if specified
        if (this.fileTypeExtension) {
          const fileExt = files[0].name.split('.').pop().toLowerCase()

          if (this.fileTypeExtension !== fileExt) {
            this.result = {
              code: 'INVALID_FILE_TYPE',
              error: true,
            }
          } else {
            // Store the dropped file directly
            this.$store.dispatch('importExport/setFile', {
              portfolioId: this.portfolio.id,
              key: this.importJobKey,
              file: files[0],
            })

            // Start the import process with the dropped file
            this.importStatus = 'VALIDATION'
            this.importCallback(files[0], false, this.selectedScenario?.id).then((result) => {
              if (['IMPORT_PROCESS_STARTED', 'IMPORT_PROCESS_COMPLETED', 'VALIDATION_ERRORS'].includes(result?.code)) {
                // Start asking for import progress
                this.watchImportProgress()
              } else if (result?.error) {
                // Clear status in case of errors
                this.importStatus = null
                this.result = result
              }
            })
          }
        }
      }
    },

    //
    onOpenResultModal() {
      this.isResultModalOpen = true
    },

    //
    onCloseResultModal() {
      this.isResultModalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-import-export-section {
  margin: var(--spacing-s) 0 var(--spacing-xl);
  min-height: 200px;

  & .content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    gap: var(--spacing-s);
    height: 100%;
  }

  & .title-container {
    width: 100%;
    min-width: 360px;
    max-width: 480px;

    & .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xxs);
      margin-bottom: var(--spacing-xs);
      height: 38px;

      & strong {
        font-size: var(--font-l);
      }

      & .subtitle {
        font-size: var(--font-m);
        color: var(--secondary-text-color);
      }
    }

    & .description {
      margin-bottom: var(--spacing-s);
    }
  }

  & .import-export-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    min-width: 600px;
    max-width: 920px;

    @media screen and (max-width: 1440px) {
      max-width: 100%;
    }

    & .export-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: var(--spacing-s);

      & .scenario-picker {
        flex: 1;
        margin-bottom: 0;
      }
    }

    & .drop-zone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 280px;

      background-color: #fcfcfc;
      border: 1px dashed #ccc;
      border-radius: var(--box-radius);
      padding: var(--spacing-l);
      transition: all 0.2s ease-in-out;
      text-align: center;

      &:hover {
        background-color: #fafafa;
        border-color: #aaa;
        cursor: pointer;
      }

      &.active {
        border-color: var(--color-primary);
        background-color: #f7fcf7;
      }

      & .drop-zone-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .drop-zone-icon {
          width: 28px;
          height: 28px;
          margin: var(--spacing-m) 0;
        }

        & .drop-zone-label {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: var(--spacing-xxs);
        }

        & .drop-zone-description {
          font-size: 14px;
        }
      }

      & .hidden {
        display: none;
      }
    }

    // Feedback box
    & .feedback-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--spacing-m);
      margin: var(--spacing-s) 0;
      padding: var(--spacing-s) var(--spacing-m);
      border: var(--box-border);
      border-radius: var(--box-radius);
      min-height: 62px;

      & .spinner-container {
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--spacing-s);
      }

      & .message-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--spacing-xs);

        & .message-button {
          padding: 0;
          border: none;
          border-radius: 2px;
          text-decoration: underline;
        }
      }

      & .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin-right: -8px;
        border: none;
        border-radius: 20px;
        background-color: transparent;

        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;
        }

        & img {
          width: 20px;
        }
      }

      & .progress-bar-container {
        width: 100%;
        height: 6px;
        background-color: #f0f0f0;
        border-radius: 3px;

        & .progress-bar {
          height: 100%;
          background-color: var(--color-primary);
          border-radius: 3px;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
</style>
